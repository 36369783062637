/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { RestoreAccount } from '../models/RestoreAccount';

export class ListRestoreAccountsResponse {
  /**
  * List of restore accounts.
  */
  'accounts': Array<RestoreAccount>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "accounts",
      "baseName": "accounts",
      "type": "Array<RestoreAccount>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListRestoreAccountsResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

